<script setup lang="ts">
import { onBeforeMount, ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import DocCategoriesService from '@/services/content-app/docCategories.service'
import { type IDocCategory } from '@/components/content-app/interface/docs/IDocCategory'
import { type IDoc_Attributes } from '@/components/content-app/interface/docs/IDoc'

const _docCategoriesService = new DocCategoriesService()
const router = useRouter()
const route = useRoute()

interface IActiveItem {
  name: string
  category: IDocCategory
}

const docsData = ref([] as IDocCategory[])
const activeItem = ref<IActiveItem>({} as IActiveItem)
const isTheSameItem = ref<number>(-1)
const isMenuHidden = ref<boolean>(true)

/**
 * Toggle the nested item
 * @param item - doc category
 */
function toggleNestedItem(item: IDocCategory) {
  docsData.value.forEach((item) => {
    if (isTheSameItem.value !== item.id) {
      item.attributes.isOpen = false
    }
    item.attributes.docs.data.forEach((doc) => {
      doc.attributes.isOpen = false
    })
  })

  item.attributes.isOpen = !item.attributes.isOpen

  router.push({
    path: `/docs/${item.attributes.Url}/${item.attributes.docs.data[0].attributes.Url}`
  })
  isTheSameItem.value = item.id
}

/**
 * toggle doc
 * @param item is doc
 */
function toggleDoc(item: IDoc_Attributes) {
  docsData.value.forEach((docCategory) => {
    docCategory.attributes.docs.data.forEach((doc) => {
      if (route.params.doc !== doc.attributes.Url) {
        doc.attributes.isOpen = false
      }
    })
  })

  item.isOpen = !item.isOpen
  router.push({ path: `/docs/${route.params.category}/${item.Url}` })
}

/**
 * toggle mob menu
 */
function toggleMobMenu() {
  isMenuHidden.value = !isMenuHidden.value
}

watch(
  () => route.path,
  (newPath) => {
    newPath && window.innerWidth < 1024 ? (isMenuHidden.value = false) : isMenuHidden.value
  }
)

onBeforeMount(async () => {
  const data = await _docCategoriesService.GetAllDocCategories()
  docsData.value = data

  if (route.name === 'documentation-category') {
    toggleNestedItem(activeItem.value.category)
  }

  if (window.innerWidth < 1024) {
    isMenuHidden.value = false
  }

  docsData.value.forEach((item) => {
    if (route.params.category === item.attributes.Url) {
      item.attributes.isOpen = true
    }
    item.attributes.docs.data.forEach((doc) => {
      if (route.params.doc === doc.attributes.Url) {
        doc.attributes.isOpen = true
      }
    })
  })
})
</script>

<template>
  <div
    class="relative flex w-full border-neutral-300 lg:h-full lg:border-r dark:border-neutral-700"
  >
    <div
      :class="isMenuHidden ? 'rounded-t-xl border-x border-t' : 'rounded-xl'"
      class="flex items-center justify-between border-neutral-300 bg-neutral-50 p-4 lg:hidden dark:border-neutral-700 dark:bg-[#25272C]"
    >
      <h5 class="text-base font-bold sm:text-xl">Documentation</h5>
      <button class="lg:hidden" @click="toggleMobMenu">
        <FontAwesomeIcon
          icon="angle-down"
          class="transition-all"
          :class="{ 'rotate-[-90deg]': !isMenuHidden }"
        />
      </button>
    </div>

    <nav
      :class="isMenuHidden ? 'z-20 border-x border-b shadow lg:shadow-none' : '-z-20 opacity-0'"
      class="scrollbar hs-accordion-group absolute top-[60px] mb-0 h-96 w-full overflow-y-auto overflow-x-hidden rounded-b-xl bg-white p-5 transition-all lg:sticky lg:top-[100px] lg:mb-10 lg:mt-7 lg:h-auto lg:border-none lg:p-1 lg:pr-4 dark:border-neutral-700 dark:bg-[#25272C] lg:dark:bg-transparent"
    >
      <div class="scrollbar w-full overflow-y-auto overflow-x-hidden pr-4">
        <div v-for="item in docsData" :key="item.id">
          <button
            class="flex w-full items-center justify-between py-1.5 font-semibold transition-all duration-300"
            :class="{
              'text-new-secondary-600 hover:text-new-secondary':
                route.params.category !== item.attributes.Url
            }"
            @click="toggleNestedItem(item)"
          >
            <p class="text-sm">
              {{ item.attributes.Title }}
            </p>

            <FontAwesomeIcon
              icon="angle-down"
              :class="{ 'rotate-[-90deg]': !item.attributes.isOpen }"
              class="transition-all duration-300"
            />
          </button>
          <div v-if="item.attributes.isOpen">
            <div v-for="doc in item.attributes.docs.data" :key="doc.id">
              <router-link
                :class="
                  route.params.doc === doc.attributes.Url
                    ? 'bg-new-secondary-50'
                    : 'text-new-secondary-700 hover:bg-new-secondary-50 hover:text-new-secondary'
                "
                class="my-1 ml-2 block rounded-lg py-1.5 pl-3 text-sm font-medium transition-all duration-300"
                v-if="!doc.attributes.sub_doc_categories.data.length"
                :to="{ path: `/docs/${item.attributes.Url}/${doc.attributes.Url}` }"
                >{{ doc.attributes.Title }}</router-link
              >
              <div v-else class="ml-2">
                <button
                  @click="toggleDoc(doc.attributes)"
                  class="my-1 flex w-full justify-between rounded-lg py-1.5 pl-3 text-sm font-semibold outline-none transition-all duration-300"
                  :class="{
                    'text-new-secondary-700  hover:text-new-secondary':
                      route.params.doc !== doc.attributes.Url
                  }"
                >
                  {{ doc.attributes.Title }}
                  <FontAwesomeIcon
                    icon="angle-down"
                    :class="{ 'rotate-[-90deg]': !doc.attributes.isOpen }"
                    class="text-base transition-all duration-300 dark:text-[#B6BAC3]"
                  />
                </button>
                <div v-if="doc.attributes.isOpen">
                  <router-link
                    :class="
                      route.params.subCategory === subdoc.attributes.Url
                        ? 'bg-new-secondary-50'
                        : 'text-new-secondary-700 hover:bg-new-secondary-50 hover:text-new-secondary'
                    "
                    class="my-1 ml-2 block rounded-lg py-1.5 pl-3.5 text-sm font-medium transition-all duration-300"
                    :to="{
                      path: `/docs/${item.attributes.Url}/${doc.attributes.Url}/${subdoc.attributes.Url}`
                    }"
                    v-for="subdoc in doc.attributes.sub_doc_categories.data"
                    :key="`subdoc${subdoc.id}`"
                  >
                    {{ subdoc.attributes.Title }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<style>
.scrollbar::-webkit-scrollbar {
  width: 5px;
}

.scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #afafaf;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
