<script lang="ts" setup>
import { onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import { useNavStore } from '@/stores/nav.store'
import LeftSideBar from '@/components/content-app/docs/DocPage/LeftSideBar.vue'
import RightSideBar from '@/components/content-app/docs/DocPage/RightSideBar.vue'
import Breadcrumb from '@/components/content-app/shared/ContentAppBreadcrumb.vue'

const route = useRoute()
const navStore = useNavStore()

onMounted(() => {
  navStore.setIsMarketingPage(true)
})

onUnmounted(() => {
  navStore.setIsMarketingPage(false)
})
</script>
<template>
  <div v-if="route.name === 'documentation-home'">
    <router-view></router-view>
  </div>
  <div v-else class="geist relative mx-5 flex lg:mx-[3%]">
    <div class="scrollbar sticky top-[72px] hidden h-[85dvh] w-1/5 overflow-y-auto lg:block">
      <LeftSideBar />
    </div>
    <div class="w-full pt-3 lg:w-3/5 lg:pl-10 lg:pt-6">
      <Breadcrumb :key="route.fullPath" />
      <LeftSideBar class="mt-3 lg:hidden" />
      <router-view :key="route.fullPath"></router-view>
    </div>
    <div class="ml-2 hidden w-1/5 lg:block">
      <RightSideBar />
    </div>
  </div>
</template>
