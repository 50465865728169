<script setup lang="ts">
import DocsService from '@/services/content-app/docs.service'
import SubDocService from '@/services/content-app/subDoc.service'
import ContentService, {
  scrollToElement,
  type IHeadingLink
} from '@/utils/content-app/content.utils'
import { onBeforeMount, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

const _contentService = new ContentService()
const _docsService = new DocsService()
const _subDocService = new SubDocService()
const headingLinks = ref([] as IHeadingLink[])
const route = useRoute()

/**
 *
 */
const renderTableContent = async () => {
  const data = await _docsService.GetDocByUrl(route.params.doc as string)
  const subDocData = await _subDocService.getSubDocbyURL(route.params.subCategory as string)
  let htmlWithConvertedIframe = null

  if (data || subDocData) {
    if (subDocData)
      htmlWithConvertedIframe = _contentService.convertOembedToIframe(subDocData.attributes.Content)
    else htmlWithConvertedIframe = _contentService.convertOembedToIframe(data.attributes.Content)
    let modifiedHtml = _contentService.injectIdIntoHtml_returnHtmlAndLinks(htmlWithConvertedIframe)
    headingLinks.value = modifiedHtml.linksArray
  }
}

onBeforeMount(() => {
  renderTableContent()
})

watch(
  () => route.path,
  () => renderTableContent()
)
</script>

<template>
  <!-- <div class="sticky min-h-max px-3 py-3"> -->
  <div class="sticky top-28 w-full pb-28 ps-2">
    <div
      v-if="headingLinks.length"
      class="md:rounded-0 order-1 col-span-2 mb-6 rounded-[20px] md:col-span-1 md:mb-0"
    >
      <div class="flex justify-between border-b border-black/10 py-4 md:border-b-0 md:p-0">
        <h6 class="text-base font-medium">TABLE OF CONTENTS</h6>
      </div>

      <div class="mb-9 mt-2 hidden w-24 border-b border-new-secondary-500 md:block lg:mb-4" />
      <div class="text-sm/10px mb-3 py-6 font-light text-[#5F7394] md:mb-12 md:p-0 lg:mb-14">
        <div
          @click.prevent="scrollToElement(`#${headingLink.linkId}`)"
          v-for="(headingLink, index) in headingLinks"
          :key="index"
          :class="index !== 0 ? 'py-1' : 'pb-1'"
          class="color-secondary-600 hover:color-secondary block cursor-pointer break-words font-normal focus:bg-red-500"
          v-html="headingLink.linkName"
        ></div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>
